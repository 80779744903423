// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/Homepage';  // Ensure the correct import here
import EduGen from './components/EduGen';
import JobLens from './components/JobLens';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />  {/* Use Homepage here */}
        <Route path="/edugen" element={<EduGen />} />
        <Route path="/joblens" element={<JobLens />} />
      </Routes>
    </Router>
  );
}

export default App;
