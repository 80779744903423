import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Use createRoot instead of ReactDOM.render
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);  // createRoot is new in React 18
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
