// src/components/Homepage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Homepage.css';

const Homepage = () => {
  return (
    <div className="homepage-container">
  <header className="header">
    <h1>Welcome to Edu 2U</h1>
    <p>AI-powered tools that elevate learning, creativity, and productivity for students and professionals alike.</p>
  </header>

  <section className="tools-section">
    <div className="tool-block">
      <div className="tool-icon">📚</div>
      <h2>EduGen</h2>
      <p>
        Empower your learning with AI-powered content generation that creates quizzes, lessons, and practice materials tailored to your subject topic, grade level, and difficulty. EduGen offers diverse content types, including quizzes and lessons, allowing you to quickly generate customized educational resources for learners of all levels.
      </p>
      <Link to="/edugen" className="tool-link">Explore EduGen</Link>
    </div>
    
    <div className="tool-block">
      <div className="tool-icon">💼</div>
      <h2>JobLens</h2>
      <p>
        Revolutionize your hiring workflow with AI-driven resume analysis that aligns candidates with job descriptions for optimal talent acquisition. JobLens provides ATS score calculation, detailed reports highlighting skill matches, gaps, and recommendations, and offers downloadable PDF reports for easy sharing.
      </p>
      <Link to="/joblens" className="tool-link">Explore JobLens</Link>
    </div>
  </section>
</div>


  );
};

export default Homepage;

