import React, { useState } from 'react';
import axios from 'axios';
import 'katex/dist/katex.min.css';
import './shared-styles.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const apiUrl = 'https://edu2u-b0fcd22669f9.herokuapp.com'; // Replace with your actual Flask backend URL

function EduGen() {
  const [subjectTopic, setSubjectTopic] = useState('');
  const [gradeLevel, setGradeLevel] = useState('Advanced');
  const [difficulty, setDifficulty] = useState('Easy');
  const [contentType, setContentType] = useState('Quiz');
  const [generatedContent, setGeneratedContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();  // Initialize useNavigate

  const gradeLevels = [
    'Advanced',
    'College',
    '12th Grade',
    '11th Grade',
    '10th Grade',
    '9th Grade',
    '8th Grade',
    '7th Grade',
    '6th Grade',
    '5th Grade',
    '4th Grade',
    '3rd Grade',
    '2nd Grade',
    '1st Grade',
  ];

  const difficultyLevels = ['Easy', 'Medium', 'Hard'];

  const contentTypes = ['Quiz', 'Practice', 'Lesson'];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setGeneratedContent('');
  
    let prompt = '';
  
    if (contentType === 'Quiz') {
      prompt = `Generate a ${difficulty.toLowerCase()} level quiz on ${subjectTopic} for ${gradeLevel.toLowerCase()} students. Include 10 multiple-choice questions with 4 options each and provide detailed explanations for each question.`;
    } else if (contentType === 'Practice') {
      prompt = `Generate 10 practice problems on ${subjectTopic} for ${gradeLevel.toLowerCase()} students. Provide the problems first and list the answers at the end. Do not include explanations.`;
    } else if (contentType === 'Lesson') {
      prompt = `Write a detailed lesson on ${subjectTopic} for ${gradeLevel.toLowerCase()} students. Explain the topic in depth as a teacher would do, with examples where appropriate.`;
    }
  
    try {
      const response = await axios.post(
        `${apiUrl}/generate-content`,
        {
          subjectTopic,
          gradeLevel,
          difficulty,
          contentType,
          prompt,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const parsedContent = parseMarkdown(response.data.content);
      setGeneratedContent(parsedContent);
    } catch (error) {
      setError('Something went wrong. Please try again.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const parseMarkdown = (content) => {
    const htmlContent = content
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Bold
      .replace(/\*(.*?)\*/g, '<em>$1</em>')  // Italic
      .replace(/\n\s*\n/g, '<br/><br/>')  // Replace multiple line breaks with paragraph breaks
      .replace(/\n/g, ' ')  // Replace single line breaks with a space
      .replace(/<br\/><br\/><br\/>/g, '<br/>')  // Replace triple <br> with a single <br>
      .replace(/A\)/g, '<br/><strong>A)</strong> ')  // Options on new lines
      .replace(/B\)/g, '<br/><strong>B)</strong> ')
      .replace(/C\)/g, '<br/><strong>C)</strong> ')
      .replace(/D\)/g, '<br/><strong>D)</strong> ')
      .replace(/Explanation:/g, '<br/><strong>Explanation:</strong>'); // Explanation header
  
    return { __html: htmlContent };
  };

  const navigateHome = () => {
    navigate('/');  // Use navigate to go back to homepage
  };

  
  return (
    <div className="App">
      <div className="container">
      <button onClick={navigateHome} className="home-button">Home</button>  {/* Add Home button */}
        <h1>Quizzes, Practice Material, Lessons and More</h1>
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label>Subject & Topic:</label>
            <input
              type="text"
              value={subjectTopic}
              onChange={(e) => setSubjectTopic(e.target.value)}
              placeholder="e.g., Math - Geometry, Cloud - AWS Data Storage"
              required
            />
          </div>
          <div className="form-group">
            <label>Grade Level:</label>
            <select
              value={gradeLevel}
              onChange={(e) => setGradeLevel(e.target.value)}
              required
            >
              {gradeLevels.map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Difficulty Level:</label>
            <select
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
              required
            >
              {difficultyLevels.map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Content Type:</label>
            <select
              value={contentType}
              onChange={(e) => setContentType(e.target.value)}
              required
            >
              {contentTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" disabled={loading} className="submit-button">
            {loading ? 'Creating Content...' : 'Craft My Content'}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {generatedContent && (
          <div className="generated-content">
            <h2>Here is Your Personalized Study Material</h2>
            <div dangerouslySetInnerHTML={generatedContent} />
          </div>
        )}
      </div>
    </div>
  );
}

export default EduGen;