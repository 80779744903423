import React, { useState } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './shared-styles.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate


function JobLens() {
  const [jobDescription, setJobDescription] = useState('');
  const [jobDescriptionFile] = useState(null);
  const [resumes, setResumes] = useState([]);
  const [reports, setReports] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [useFile] = useState(false);
  const navigate = useNavigate();  // Initialize useNavigate

  const navigateHome = () => {
    navigate('/');  // Use navigate to go back to homepage
  };

  const handleFileUpload = (e) => {
    setResumes(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');  // Clear any previous errors
    setReports([]);  // Clear previous reports when user clicks submit

    const formData = new FormData();
    if (useFile && jobDescriptionFile) {
      formData.append('job_description_file', jobDescriptionFile);
    } else {
      formData.append('job_description', jobDescription);
    }

    for (let i = 0; i < resumes.length; i++) {
      formData.append('resumes', resumes[i]);
    }

    try {
      const response = await axios.post('https://joblens-97eeeee433d9.herokuapp.com/analyze', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const responseData = response.data.reports;
      setReports(responseData);
    } catch (error) {
      setErrorMessage('An error occurred: ' + (error.response?.data?.error || 'Unexpected error. Please try again.'));
    }

    setLoading(false);
  };

  // Helper function to safely render HTML content in React
  const renderHTML = (rawHTML) => {
    return { __html: rawHTML };
  };

  // Render the report and ensure HTML tags are parsed correctly
  const formatReport = (reportContent) => {
    const formattedReport = reportContent
      .replace(/###/g, '') // Remove placeholder symbols if any
      .replace(/\*\*/g, '') // Remove asterisks
      .replace(/(?:^|\n)([A-Za-z\s]+:)/g, '<strong>$1</strong>') // Bold titles ending with a colon
      .replace(/\n/g, '<br><br />') // Convert newlines to line breaks
      .replace(/%/g, '%<br/>'); // Convert newlines to line breaks

    return formattedReport;
  };

  // Function to download a specific report as PDF
  const downloadPDF = (reportId, candidateName) => {
    const input = document.getElementById(`report-${reportId}`); // Find the element to capture

    if (!input) {
      console.error('Element not found for the given report ID:', reportId);
      return;  // Avoid further execution if the element is not found
    }

    html2canvas(input, { scale: 1.5 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
      });

      const imgWidth = 595.28; // A4 width in pt
      const pageHeight = 841.89; // A4 height in pt
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      const filename = candidateName ? `${candidateName}_Resume_Analysis_Report.pdf` : `report-${reportId}.pdf`;
      pdf.save(filename);
    });
  };

  return (
    <div className="App">
      <button onClick={navigateHome} className="home-button">Home</button>  {/* Add Home button */}
      <h1>JobLens - Resume vs Job Description</h1>
      <form onSubmit={handleSubmit} className='form'>
        <div className="form-group">
          <label>Job Description:</label>
          <textarea
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            placeholder="Enter job description here"
          />
        </div>
        <div className="form-group">
          <label>Upload Resumes:</label>
          <input type="file" multiple onChange={handleFileUpload} />
        </div>
        <button type="submit">{loading ? 'Analyzing...' : 'Submit'}</button>
      </form>
  
      {reports.length > 0 && (
        <div className="reports">
          {reports.map((report, index) => (
            <div key={index} className="report" id={`report-${index}`}>
              <div className="report-header">
                <strong>{report.candidate_name}'s Resume Analysis Report</strong>
                <button
                  className="pdf-button"
                  onClick={() => downloadPDF(index, report.candidate_name)}
                >
                  Download as PDF
                </button>
              </div>
              <div className="report-content">
                <div dangerouslySetInnerHTML={renderHTML(formatReport(report.report))} />
              </div>
            </div>
          ))}
        </div>
      )}
  
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

export default JobLens;
